import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { css } from "@emotion/core"

import { Link } from "gatsby"
import { Box } from "@theme-ui/components"

export default () => {
  const data = useStaticQuery(graphql`
    query MDXQuery {
      mdx {
        id
        body
      }
    }
  `)
  return (
    <Box
      ml={[3, 5]}
      mr={[2, 4]}
      mt={[2, 4]}
      css={css`
        @media print {
          font-family: "arial";
        }
      `}
    >
      <Helmet>
        <html lang="en" />
        <title>Resume - Joé Jemmely</title>
      </Helmet>
      <Link to="/">Back</Link>
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
    </Box>
  )
}
